import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledIcon = styled.div`
  display: ${(props) => props.display || 'block'};
  position: absolute;
  z-index: 4;
  color: red;
  width: ${(props) => props.width || '40px'};
  height: ${(props) => props.height || '40px'};
  overflow: hidden;
  text-indent: -9999px;
  background-image: url(${(props) => props.imageUri || ''});
  background-color: ${(props) => props.theme.debugColorPlacement};
  background-position: center center;
  background-size: ${(props) => props.width || '40px'}
    ${(props) => props.height || '40px'};
  background-repeat: no-repeat;
  left: ${(props) => props.x || 0};
  top: ${(props) => props.y || 0};
  cursor: no-cursor;
`;
const Icon = ({
  config,
  type,
  className,
  copyModesAvailable,
  copyModeCurrent,
  colorModeCurrent,
  isPointer,
  isBlinking,
  isVisible
}) => {
  let display = 'block';
  if (!isVisible) {
    display = 'none';
  }

  // Sets blink to the selected copyMode or colorMode icon
  if (copyModeCurrent || colorModeCurrent) {
    if (type === copyModeCurrent) {
      // Added in isPointer detect to reuse copyModesAvailable logic
      if (isPointer === true) {
        className = 'blink';
        display = 'block';
      }
    }
    if (type === colorModeCurrent) {
      className = 'blink';
    }
  }

  // Override blink if not captured above
  if (isBlinking) {
    className = 'blink';
  }

  // Determines visibility of copy mode icons
  if (copyModesAvailable) {
    let isCopyModeAvailable = copyModesAvailable.includes(type);
    if (!isCopyModeAvailable) {
      display = 'none';
    }
  }

  return (
    <StyledIcon
      imageUri={config.active}
      x={config.x}
      y={config.y}
      width={config.width}
      height={config.height}
      className={className}
      display={display}
    >
      {type}
    </StyledIcon>
  );
};

Icon.defaultProps = {
  copyModeCurrent: null,
  colorModeCurrent: null,
  isPointer: false,
  isBlinking: false,
  isVisible: true
};

Icon.propTypes = {
  config: PropTypes.object,
  copyModesAvailable: PropTypes.array,
  type: PropTypes.string,
  copyModeCurrent: PropTypes.string,
  colorModeCurrent: PropTypes.string,
  isPointer: PropTypes.bool,
  isBlinking: PropTypes.bool,
  isVisible: PropTypes.bool
};

export default Icon;
