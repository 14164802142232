import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
//import { CONFIG } from '../../constants/';

const StyledButton = styled.button`
  display: block;
  position: absolute;
  z-index: 5;
  width: ${(props) => props.width || '40px'};
  height: ${(props) => props.height || '40px'};
  overflow: hidden;
  background-image: url(${(props) => props.imageUriActive || ''});
  background-color: ${(props) => props.theme.debugColorPlacement};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 0;
  margin: 0;
  left: ${(props) => props.x || 0};
  top: ${(props) => props.y || 0};
  cursor: no-cursor;
  border: 0;
  :active {
    background-image: url(${(props) => props.imageUriInactive || ''});
  }
`;
const StyledImage = styled.div`
  display: inline-block;
  position: relative;
  pointer-events: none;
  z-index: 8;
  color: red;
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '100%'};
  overflow: hidden;
  text-indent: -9999px;
  background-image: url(${(props) => props.imageUri || ''});
  background-color: ${(props) => props.theme.debugColorPlacement};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: no-cursor;
  margin: 0 auto;
  padding: 0;
`;

const Button = ({
  onClick,
  config,
  type,
  currentState,
  isSystemOn,
  className,
  colorMode,
  isActive,
  isPowerBlinking,
  isCopyBlinking,
  onTouchStart,
  onTouchEnd,
  onTouchMove,
  onMouseDown,
  onMouseLeave,
  onMouseUp
}) => {
  const CONFIG = window.CONFIG; // It works!

  //console.log('[Button] isSystemOn = ', isSystemOn );
  let currentImage = config.active,
    currentOnClick = onClick,
    isDisabled = false,
    buttonWidth = CONFIG.assets[config.type].width,
    buttonHeight = CONFIG.assets[config.type].height,
    buttonImageUriActive = CONFIG.assets[config.type].active,
    buttonImageUriInactive = CONFIG.assets[config.type].inactive;

  // Establishes if printer is on and shows the correct icon
  if (isSystemOn) {
    // isSystemOn === true
    currentImage = config.active;
    if (currentState === 'default' && type !== 'power') {
      // Case of powered up state with no paper
      isDisabled = true;
    }
    if (currentState === 'printing') {
      currentOnClick = onClick;
      isDisabled = false;
    }
    // Minus and Plus action override to show inactive status on those buttons
    if (isActive === false) {
      //console.log('  [isActive] ' + type + ' ' + isActive);
      currentImage = config.inactive;
    }

    // Color or BW toggle
    if (
      colorMode === 'cmyk' &&
      isSystemOn &&
      type === 'copy' &&
      currentState !== 'default'
    ) {
      currentImage = config.activeColor;
    }
    if (
      colorMode === 'k' &&
      isSystemOn &&
      type === 'copy' &&
      currentState !== 'default'
    ) {
      currentImage = config.active;
    }

    if (isCopyBlinking) {
      currentOnClick = onClick;
      className = 'blinkButton';
      isDisabled = false;
    }

    // Keep wifi and info active when powered on
    if (type === 'wifi' || type === 'info') {
      isDisabled = false;
    }
  } else {
    // isSystemOn === false
    currentImage = config.inactive;
    if (type !== 'power') {
      isDisabled = true;
    }
  }

  if (currentState === 'printing') {
    //console.log('  Printing state changes...');
    switch (type) {
      case 'copy':
        if (!isCopyBlinking) {
          //console.log('  Disable copy');
          isDisabled = true;
        }
        break;
      case 'ellipsis':
        //console.log('  Disable ellipsis');
        isDisabled = true;
        currentImage = config.inactive;
        break;
      case 'minus':
      case 'plus':
        //console.log('  Disable minus/plus');
        isDisabled = true;
        currentImage = config.inactive;
        break;
      default:
      //console.log('  Nothing');
    }
  }

  if (isPowerBlinking) {
    className = 'blinkButton';
    currentImage = config.active;
  }

  return (
    <StyledButton
      onClick={currentOnClick}
      x={config.x}
      y={config.y}
      width={buttonWidth}
      height={buttonHeight}
      disabled={isDisabled}
      imageUriActive={buttonImageUriActive}
      imageUriInactive={buttonImageUriInactive}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseLeave}
      onTouchMove={onTouchMove}
    >
      <StyledImage
        imageUri={currentImage}
        width={config.width}
        height={config.height}
        disabled={isDisabled}
        className={className}
      >
        {type}
      </StyledImage>
    </StyledButton>
  );
};

Button.defaultProps = {
  isActive: true,
  isSystemOn: true,
  colorMode: 'k',
  isPowerBlinking: false,
  isCopyBlinking: false,
  onTouchStart: undefined,
  onTouchEnd: undefined,
  onTouchMove: undefined,
  onMouseDown: undefined,
  onMouseUp: undefined,
  onMouseLeave: undefined,
  onClick: undefined
};

Button.propTypes = {
  onTouchStart: PropTypes.func,
  onTouchEnd: PropTypes.func,
  onTouchMove: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func,
  isSystemOn: PropTypes.bool,
  isActive: PropTypes.bool,
  config: PropTypes.object,
  colorMode: PropTypes.string,
  type: PropTypes.string,
  currentState: PropTypes.string,
  styleName: PropTypes.string,
  isPowerBlinking: PropTypes.bool,
  isCopyBlinking: PropTypes.bool
};

export default Button;
