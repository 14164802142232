import React, { Component } from 'react';
import styled from 'styled-components';
import { OPTIONS } from '../../constants/';
import PrinterPanel from '../PrinterPanel';
import AdminButton from '../AdminButton';

const StyledScenarioPage = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.feedbackOverlayBgColor};
  color: ${(props) => props.theme.lightColor};
  margin: 10px;
  text-align: center;
  width: 100vw;
  .tl {
    position: absolute;
    top: 0;
    left: 0;
  }
  h1 {
    font-size: 1.5em;
    padding: 25px 25px;
    margin: 10px auto;
  }
  p.scenario {
    display: inline-block;
    font-size: 1.25em;
    padding: 25px 25px;
    margin: 10px;
    border: 1px solid ${(props) => props.theme.lightColor};
    border-radius: 15px;
  }
`;

class ScenarioPage extends Component {
  constructor(props) {
    super(props);

    this.goBackHome = this.goBackHome.bind(this);

    this.setConfig = this.setConfig.bind(this);
    this.state = {
      config: null
    };
  }

  scriptLoaded() {
    //console.log('script loaded!');
    const CONFIG = window.CONFIG;
    //console.log('CONFIG.version=', CONFIG.version);
    this.setState({
      config: CONFIG
    });
  }

  setConfig(index) {
    //console.log(index)
    const script = document.createElement('script');
    script.src = process.env.PUBLIC_URL + '/' + OPTIONS.paths[index].filename;
    script.async = true;
    script.onload = () => this.scriptLoaded();
    document.body.appendChild(script);
  }

  goBackHome() {
    // TODO: Remove the previous script additions?
    this.setState({
      config: null
    });
  }

  render() {
    return (
      <StyledScenarioPage>
        {this.state.config !== null && (
          <div className="tl">
            <AdminButton
              text="Go back home"
              onClick={this.goBackHome}
              isDebug={OPTIONS.isDebug}
            />
          </div>
        )}
        {this.state.config === null && <h1>{OPTIONS.title}</h1>}
        {this.state.config === null &&
          OPTIONS.paths.map((path, index) => (
            <p
              className="scenario"
              key={'k' + index}
              onClick={() => this.setConfig(index)}
            >
              {path.name}
            </p>
          ))}
        {this.state.config !== null && (
          <PrinterPanel config={this.state.config} isDebug={OPTIONS.isDebug} />
        )}
      </StyledScenarioPage>
    );
  }
}

export default ScenarioPage;
