import React from 'react';
import { render } from 'react-dom';
import { HashRouter, Switch, Route } from 'react-router-dom';
import './styles/index.scss';
import { ThemeProvider } from 'styled-components';
import ScenarioPage from './components/ScenarioPage';
import * as serviceWorker from './serviceWorker';

// https://medium.com/styled-components/getting-sassy-with-sass-styled-theme-9a375cfb78e8
// Extract our Sass variables into a JS object
// eslint-disable-next-line import/no-webpack-loader-syntax
const theme = require('sass-extract-loader?{"plugins": ["sass-extract-js"]}!./styles/_variables.scss');

render(
  <ThemeProvider theme={theme}>
    <HashRouter>
      <Switch>
        <Route exact path="/" component={ScenarioPage} />
      </Switch>
    </HashRouter>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
