import React, { Component } from 'react';
//import { CONFIG } from '../../constants/';
import Button from '../Button';
import Icon from '../Icon';
import ContrastIcon from '../ContrastIcon';
import NumberCount from '../NumberCount';
import FeedbackPopUp from '../FeedbackPopUp';
import AdminButton from '../AdminButton';
import CopyResultsDebug from '../CopyResultsDebug';
import AlertPopUp from '../AlertPopUp';
import styled from 'styled-components';

const StyledPreload = styled.div`
  position: fixed;
  display: block;
  top: 0;
  right: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;
const StyledPrinterDemo = styled.div`
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`;
const StyledPrinterPanel = styled.div`
  transform: scale(${(props) => props.scale || '1'});
  position: absolute;
  z-index: 5;
  display: block;
  background-image: url(./assets/images/fullpanel-blank-nobuttons.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 1192px;
  height: 113px;
  left: ${(props) => props.x || '0'};
  top: ${(props) => props.y || '0'};
`;
const StyledAdminControls = styled.div`
  position: absolute;
  z-index: 8;
  width: 100vw;
  bottom: 100px;
  height: 100px;
  padding: 0;
  margin: 0;
  p {
    color: ${(props) => props.theme.debugColor};
    position: relative;
    padding: 25px;
    margin: 0;
    width: 50%;
    font-size: ${(props) => props.theme.fontSizeSmall};
    float: left;
    text-align: left;
  }
  .AdminButtons {
    width: 40%;
    margin: 0 0 0 auto;
    position: relative;
    text-align: right;
    float: right;
  }
`;
class PrinterPanel extends Component {
  constructor(props) {
    super(props);

    this.onTogglePower = this.onTogglePower.bind(this);
    this.onCopy = this.onCopy.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSectionToggle = this.onSectionToggle.bind(this);

    this.onToggleAlert = this.onToggleAlert.bind(this);

    this.onWifi = this.onWifi.bind(this);
    this.onInfo = this.onInfo.bind(this);
    this.onArrow = this.onArrow.bind(this);
    this.preloadedAssets = [];

    this.onLiftScanLid = this.onLiftScanLid.bind(this);
    this.onShowEverything = this.onShowEverything.bind(this);
    this.hideFeedback = this.hideFeedback.bind(this);

    // Minus and Plus downs
    this.onMinus = this.onMinus.bind(this);
    this.onPlus = this.onPlus.bind(this);

    // Mouse down help props
    this.t = undefined;
    this.start = 2000; // Overridden by data.xxxx.js settings
    this.isFirstPress = true; // Sets for press situation on long press logic

    this.onMouseDown = this.onMouseDown.bind(this);
    this.repeat = this.repeat.bind(this);
    this.increment = this.increment.bind(this);

    this.decrement = this.decrement.bind(this);
    this.decrementRepeat = this.decrementRepeat.bind(this);
    this.decrementDown = this.decrementDown.bind(this);

    this.onMouseUp = this.onMouseUp.bind(this);

    this.state = {
      showFeedback: false, // Toggle for feedback popup
      showAlert: false, // Toggle for alert popup
      alertMessage: '',
      isOn: false, // Toggle for system power
      isActive: false, // Determines appearance of Cancel button
      isIdCopyAvailable: false, // Determines if Id Copy button is available
      isConclusionGeneric: false, // Determines if we should use default conclusion statement
      isOnEthernet: false,
      isOnWifi: true,
      isOnBluetooth: true,
      isOnToner: false,
      isOnInfo: false,
      lidOpen: false,
      mediaAlert: false,
      panelStates: ['copy', 'contrast', 'scale', 'copyMode'],
      panelStatesCISS: ['copy', 'colorMode', 'copyMode'],
      currentPanelState: 'default',
      copyModes: ['document', 'id', 'photo'],
      copyModesAltCISS: ['document', 'id'],
      colorModes: ['k', 'cmyk'],

      scaleCurrent: 100, // live value, default is found in CONFIG.scaleDefault
      scaleCurrentHolder: 100, // Used to hold previous scale while colorMode toggles. A bit lame need.

      colorModeCurrent: 'k', // live value, default is found in CONFIG.colorModeDefault
      colorModeCurrentHolder: 'k', // Used to hold previous scale while colorMode toggles. A bit lame need.

      copyModePrevious: 'document', // temporary capture of previous copy mode state to help with idcopy change.
      copyModeCurrent: 'document', // live value, default is found in CONFIG.copyModeDefault

      contrastCurrent: 3, // live value, default is found in CONFIG.contrastDefault

      copyCurrent: 0, // live value, default is found in CONFIG.copyDefault
      copyCurrentHolder: 0, // Used to hold original copyCurrent, for use to swap with printing action

      feedbackContent: '',
      feedbackClassName: '',
      feedbackOverride: true,

      isPowerBlinking: false,
      isMinusActive: false,
      isPlusActive: false,
      isCancelActive: false,
      isArrowActive: false,
      isCopyActive: false,
      isSelectionActive: false,
      isTemp: false,

      isId1Blinking: false,
      isIdFrontBlinking: false,
      isIdFrontVisible: false,
      isId2Blinking: false,
      isIdBackBlinking: false,
      isIdBackVisible: false,
      isLidOpenVisible: false,
      isLidOpenBlinking: false,
      isCopyBlinking: false,
      isNumberBlinking: false,

      isAdminIdCopyButtonVisible: false,

      isInsertOriginalVisible: false // Provides toggle to show/hide Insert Original button
    };
  }

  componentDidMount() {
    this.preloadAssets(); // Preload assets
    this.loadConfig(); // Load config
  }

  componentWillUnmount() {
    console.log('componentWillUnmount');
  }

  componentDidUpdate(prevProps, prevState) {
    //console.log('  [componentDidUpdate] ------ ');
    // Determines correct values for isMinusActive and isPlusActive when section changes
    if (prevState.currentPanelState !== this.state.currentPanelState) {
      console.log(
        '  [componentDidUpdate]: Panel state did change, fix minus and plus buttons...'
      );
      this.evaluateMinusPlusState(this.state.currentPanelState);
    }

    // Determine if copyMode was changed
    if (prevState.copyModeCurrent !== this.state.copyModeCurrent) {
      //console.log('  [componentDidUpdate]: copyModeCurrent changed' );
      // Determine if the new state is id, affect scale settings
      switch (this.state.copyModeCurrent) {
        case 'id':
          console.log('  [componentDidUpdate]: id selected!');
          // Added colorModes and colorModeCurrent to resolve minus direction cycle
          this.setState({
            scaleCurrentHolder: prevState.scaleCurrent,
            scaleCurrent: this.props.config.scaleDefault,
            scaleDefault: this.props.config.scaleDefault,
            scaleUpperLimit: this.props.config.scaleDefault,
            scaleLowerLimit: this.props.config.scaleDefault,
            colorModes: this.props.config.colorModes,
            colorModeCurrent: this.state.colorModeCurrentHolder
          });
          break;
        case 'photo':
          console.log('  [componentDidUpdate]: Photo selected! CMYK only.');
          this.setState({
            colorModeCurrentHolder: prevState.colorModeCurrent,
            colorModeCurrent: 'cmyk',
            colorModes: ['cmyk']
          });
          break;
        default:
          // document state
          // For other ones, reset scale back to default and resurrect previous value
          console.log('  [componentDidUpdate]: Reset to default');
          this.setState(
            {
              scaleCurrent: this.state.scaleCurrentHolder,
              scaleDefault: this.props.config.scaleDefault,
              scaleUpperLimit: this.props.config.scaleUpperLimit,
              scaleLowerLimit: this.props.config.scaleLowerLimit,
              colorModes: this.props.config.colorModes,
              copyCurrent: this.state.copyCurrent,
              //colorModeCurrent: this.state.colorModeCurrentHolder
              colorModeCurrent: this.props.config.colorModeDefault // TODO: Can this be toggled programmatically?
            },
            () => {
              this.evaluateCancelState();
            }
          );
      }
    }

    // Determines if the following have changed that would affect isMinusActive and isPlusActive
    // - copyCurrent
    // - scaleCurrent
    // - contrastCurrent
    // - copyModeCurrent
    // - colorModeCurrent
    if (
      prevState.copyCurrent !== this.state.copyCurrent ||
      prevState.scaleCurrent !== this.state.scaleCurrent ||
      prevState.contrastCurrent !== this.state.contrastCurrent ||
      prevState.copyModeCurrent !== this.state.copyModeCurrent ||
      prevState.colorModeCurrent !== this.state.colorModeCurrent
    ) {
      //console.log('  [componentDidUpdate]: User changes values from previous state...');
      this.evaluateMinusPlusState(this.state.currentPanelState);
    }

    // Determines if isCancelActive if active or not
    if (
      prevState.copyCurrent !== this.state.copyCurrent ||
      prevState.contrastCurrent !== this.state.contrastCurrent ||
      prevState.scaleCurrent !== this.state.scaleCurrent ||
      prevState.copyModeCurrent !== this.state.copyModeCurrent ||
      prevState.colorModeCurrent !== this.state.colorModeCurrent
    ) {
      //console.log('  [componentDidUpdate]: User changes values away from default...');
      this.evaluateCancelState();
    }
  }

  // Loads configuration values from external data.js file in public.
  loadConfig() {
    console.log('[loadConfig] Loading configuration from JS');

    this.start = this.props.config.pressStartDuration;
    this.setState({
      showAlert: false,
      copyCurrent: this.props.config.copyDefault,
      contrastCurrent: this.props.config.contrastDefault,
      scaleCurrent: this.props.config.scaleDefault,
      scaleUpperLimit: this.props.config.scaleUpperLimit,
      scaleLowerLimit: this.props.config.scaleLowerLimit,
      currentContrastSetting: this.props.config.currentContrastSetting,
      panelStates: this.props.config.panelStates,
      currentPanelState: this.props.config.startingPanelState,
      copyModes: this.props.config.copyModes,
      isIdCopyAvailable: this.props.config.isIdCopyAvailable,
      isConclusionGeneric: this.props.config.isConclusionGeneric,
      isOnEthernet: this.props.config.isOnEthernet,
      isOnBluetooth: this.props.config.isOnBluetooth,
      isOnWifi: this.props.config.isOnWifi,
      isOnToner: this.props.config.isOnToner,
      isOnInfo: this.props.config.isOnInfo,
      lidOpen: this.props.config.lidOpen,
      mediaAlert: this.props.config.mediaAlert,
      colorModes: this.props.config.colorModes,
      colorModeCurrent: this.props.config.colorModeDefault,
      copyModeCurrent: this.props.config.copyModeDefault
    });
  }

  // Toggles the power on printer
  onTogglePower() {
    console.log('[onTogglePower] ===============');

    let newIsOn = !this.state.isOn;

    if (newIsOn) {
      //console.log(' - Powering on Printer / Blink three times then active');
      this.setState(
        {
          isPowerBlinking: true
        },
        () => {
          this.thisTimer = setTimeout(() => {
            clearTimeout(this.thisTimer);
            this.loadConfig();
            this.setState({
              currentPanelState: 'default',
              isOn: newIsOn,
              isPlusActive: false,
              isMinusActive: false,
              isPowerBlinking: false,
              isInsertOriginalVisible: true
            });
          }, this.props.config.powerDuration);
        }
      );
    } else {
      // console.log(' - Powering down Printer / Blink three times then inactive');
      this.setState(
        {
          isPowerBlinking: true
        },
        () => {
          this.thisTimer = setTimeout(() => {
            clearTimeout(this.thisTimer);
            this.setState({
              currentPanelState: 'default',
              isOn: newIsOn,
              isPlusActive: false,
              isCopyActive: false,
              isSelectionActive: false,
              isMinusActive: false,
              isPowerBlinking: false,
              showFeedback: false,

              isLidOpenVisible: false,
              isLidOpenBlinking: false,
              isId1Visible: false,
              isId1Blinking: false,
              isIdFrontVisible: false,
              isIdFrontBlinking: false,
              isId2Visible: false,
              isId2Blinking: false,
              isIdBackVisible: false,
              isIdBackBlinking: false,
              isNumberBlinking: false
            });
          }, this.props.config.powerDuration);
        }
      );
    }
  }

  onMinus(stepCount) {
    if (!stepCount) {
      stepCount = 1;
    }
    switch (this.state.currentPanelState) {
      case 'copy':
        let newCopyCount = this.state.copyCurrent - stepCount;
        if (newCopyCount < this.props.config.copyLowerLimit) {
          newCopyCount = this.props.config.copyLowerLimit;
        } else if (newCopyCount > this.props.config.copyUpperLimit) {
          newCopyCount = this.props.config.copyUpperLimit;
        }
        this.setState({
          copyCurrent: newCopyCount
        });
        console.log('onMinus', newCopyCount);
        break;
      case 'scale':
        let newScale = this.state.scaleCurrent - stepCount;
        if (newScale < this.state.scaleLowerLimit) {
          newScale = this.state.scaleLowerLimit;
        } else if (newScale > this.state.scaleUpperLimit) {
          newScale = this.state.scaleUpperLimit;
        }
        this.setState({
          scaleCurrent: newScale
        });
        console.log('onMinus', newScale);
        break;
      case 'contrast':
        let newContrastCurrent = this.state.contrastCurrent - stepCount;
        if (newContrastCurrent < this.props.config.contrastLowerLimit) {
          newContrastCurrent = this.props.config.contrastLowerLimit;
        } else if (newContrastCurrent > this.props.config.contrastUpperLimit) {
          newContrastCurrent = this.props.config.contrastUpperLimit;
        }
        this.setState({
          contrastCurrent: newContrastCurrent
        });
        console.log('onMinus', newContrastCurrent);
        break;
      case 'colorMode':
        this.onColorModeToggle('minus');
        break;
      case 'copyMode':
        this.onCopyModeToggle('minus');
        break;
      default:
        console.log('onMinus -- default case');
    }
  }

  onMouseDown(e) {
    e.preventDefault();
    if (this.state.currentPanelState !== 'default') {
      //this.increment();
      this.repeat();
      this.setState({
        isNumberBlinking: false
      });
    }
  }

  onMouseUp(e) {
    e.preventDefault();
    if (this.state.currentPanelState !== 'default') {
      clearTimeout(this.t);
      this.start = this.props.config.pressStartDuration;
      this.setState({
        isNumberBlinking: true
      });
      this.isFirstPress = true;
    }
  }

  repeat() {
    //console.log('  - repeat: ' + this.start + ', pressRampDuration=' + this.props.config.pressRampDuration);
    clearTimeout(this.t);

    if (this.isFirstPress) {
      this.isFirstPress = false;
      this.increment(this.props.config.pressDefaultValue);
    } else {
      this.increment(this.props.config.pressLongValue);
    }
    this.t = setTimeout(this.repeat, this.start);
    //this.start = this.start / this.props.config.pressRamp;
    //this.start = this.props.config.pressStartDuration / this.props.config.pressRamp;
    this.start = this.props.config.pressRampDuration;
    console.log('repeat: this.start=', this.start);
  }

  increment(value) {
    //console.log('  - increment');
    //this.setState({ copyCurrent: this.state.copyCurrent + 1 });
    this.onPlus(value);
  }

  // decrement
  decrement(value) {
    //console.log('  - decrement');
    //this.setState({ copyCurrent: this.state.copyCurrent - 1 });
    this.onMinus(value);
  }

  decrementRepeat() {
    //console.log('  - decrementRepeat');
    if (this.isFirstPress) {
      this.isFirstPress = false;
      this.decrement(this.props.config.pressDefaultValue);
    } else {
      this.decrement(this.props.config.pressLongValue);
    }
    this.t = setTimeout(this.decrementRepeat, this.start);
    //this.start = this.start / this.props.config.pressRamp;
    //this.start = this.props.config.pressStartDuration / this.props.config.pressRamp;
    this.start = this.props.config.pressRampDuration;
  }

  decrementDown(e) {
    e.preventDefault();
    //console.log('  - decrementDown');
    if (this.state.currentPanelState !== 'default') {
      this.setState({
        isNumberBlinking: false
      });
      this.decrementRepeat();
    }
  }

  onPlus(stepCount) {
    if (!stepCount) {
      stepCount = 1;
    }
    switch (this.state.currentPanelState) {
      case 'copy':
        let newCopyCount = this.state.copyCurrent + stepCount;
        if (newCopyCount > this.props.config.copyUpperLimit) {
          newCopyCount = this.props.config.copyUpperLimit;
        }
        this.setState({
          copyCurrent: newCopyCount
        });
        console.log('onPlus ' + this.state.currentPanelState, newCopyCount);
        break;
      case 'scale':
        let newScale = this.state.scaleCurrent + stepCount;
        if (newScale > this.state.scaleUpperLimit) {
          newScale = this.state.scaleUpperLimit;
        }
        this.setState({
          scaleCurrent: newScale
        });
        console.log('onPlus ' + this.state.currentPanelState, newScale);
        break;
      case 'contrast':
        let newContrastCurrent = this.state.contrastCurrent + stepCount;
        if (newContrastCurrent > this.props.config.contrastUpperLimit) {
          newContrastCurrent = this.props.config.contrastUpperLimit;
        }
        this.setState({
          contrastCurrent: newContrastCurrent
        });
        console.log(
          'onPlus ' + this.state.currentPanelState,
          newContrastCurrent
        );
        break;
      case 'colorMode':
        this.onColorModeToggle('plus');
        break;
      case 'copyMode':
        this.onCopyModeToggle('plus');
        break;
      default:
        console.log('onPlus -- default case');
    }
  }

  // Determines if isMinusActive and isPlusActive are in the correct state based on sectionName
  evaluateMinusPlusState(sectionName) {
    console.log('[evaluateMinusPlusState] Start', sectionName);
    // Forcing true, for the homies
    let newIsMinusActive = true,
      newIsPlusActive = true;

    // Compares between default constants and the associated current states.
    // Only flipping to false if limits detected
    switch (sectionName) {
      case 'copy':
        if (this.state.copyCurrent === this.props.config.copyUpperLimit) {
          newIsPlusActive = false;
        }
        if (this.state.copyCurrent === this.props.config.copyLowerLimit) {
          newIsMinusActive = false;
        }
        break;
      case 'contrast':
        if (
          this.state.contrastCurrent === this.props.config.contrastUpperLimit
        ) {
          newIsPlusActive = false;
        }
        if (
          this.state.contrastCurrent === this.props.config.contrastLowerLimit
        ) {
          newIsMinusActive = false;
        }
        break;
      case 'scale':
        if (this.state.scaleCurrent === this.state.scaleUpperLimit) {
          newIsPlusActive = false;
        }
        if (this.state.scaleCurrent === this.state.scaleLowerLimit) {
          newIsMinusActive = false;
        }
        break;
      case 'copyMode':
        //console.log('  No need for minus and plus logic here, infinite loop');
        break;
      case 'colorMode':
        console.log(
          '  Color mode length = ' + this.state.colorModes.length,
          this.state.colorModes
        );
        if (this.state.colorModes.length === 1) {
          newIsPlusActive = false;
          newIsMinusActive = false;
        }
        break;
      case 'printing': // Capture printing state
        newIsPlusActive = false;
        newIsMinusActive = false;
        break;
      default:
        console.log('  Nothing updates on default');
        newIsPlusActive = false;
        newIsMinusActive = false;
    }

    if (this.state.isMinusActive !== newIsMinusActive) {
      //console.log('  Setting new isMinusActive', newIsMinusActive);
      this.setState({
        isMinusActive: newIsMinusActive
      });
    }
    if (this.state.isPlusActive !== newIsPlusActive) {
      //console.log('  Setting new isPlusActive', newIsPlusActive);
      this.setState({
        isPlusActive: newIsPlusActive
      });
    }

    //console.log('[evaluateMinusPlusState] End');
  }

  // Determines isCancelActive based on comparison with defaults values
  evaluateCancelState() {
    // Sets isCancelActive if it is needed
    let currentCancelState = this.state.isCancelActive,
      isCancelActive = this.state.isCancelActive;

    // The default values
    let contrastDefault = this.props.config.contrastDefault,
      scaleDefault = this.props.config.scaleDefault,
      copyDefault = this.props.config.copyDefault,
      copyModeDefault = this.props.config.copyModeDefault,
      colorModeDefault = this.props.config.colorModeDefault;

    // The current values
    let contrastCurrent = this.state.contrastCurrent,
      scaleCurrent = this.state.scaleCurrent,
      copyCurrent = this.state.copyCurrent,
      copyModeCurrent = this.state.copyModeCurrent,
      colorModeCurrent = this.state.colorModeCurrent;

    /*
    console.log('[evaluateCancelState] Comparison');
    console.log('  Copy: ' + copyDefault + ' === ' + copyCurrent);
    console.log('  Contrast: ' + contrastDefault + ' === ' + contrastCurrent);
    console.log('  Scale: ' + scaleDefault + ' === ' + scaleCurrent);
    console.log('  Copy Mode: ' + copyModeDefault + ' === ' + copyModeCurrent);
    console.log('  Color Mode: ' + colorModeDefault + ' === ' + colorModeCurrent);
    */

    if (
      contrastDefault === contrastCurrent &&
      scaleDefault === scaleCurrent &&
      copyDefault === copyCurrent &&
      copyModeDefault === copyModeCurrent &&
      colorModeDefault === colorModeCurrent
    ) {
      isCancelActive = false;
      //console.log('[evaluateCancelState] It should be inactive');
    } else {
      isCancelActive = true;
      //console.log('[evaluateCancelState] It should be active');
    }

    if (currentCancelState !== isCancelActive) {
      this.setState({
        isCancelActive: isCancelActive
      });
    }
  }

  // Constantly advances copyMode, infinite loop
  onCopyModeToggle(direction) {
    // Valid: minus, plus
    //  console.log('[onCopyModeToggle] Start');
    let newCopyMode,
      newCopyModeIndex,
      copyModeCurrent = this.state.copyModeCurrent,
      copyModes = this.state.copyModes;

    // Find index of currentCopymode in copyModes
    let copyModeCurrentIndex = copyModes.findIndex(
      (copyMode) => copyMode === copyModeCurrent
    );

    if (direction === 'minus') {
      // -1 to that index
      newCopyModeIndex = copyModeCurrentIndex - 1;

      // Check if bottom limit reached, if yes, go to end
      if (newCopyModeIndex < 0) {
        newCopyMode = copyModes[copyModes.length - 1];
      } else {
        newCopyMode = copyModes[newCopyModeIndex];
      }
    } else {
      // +1 to that index
      newCopyModeIndex = copyModeCurrentIndex + 1;

      // Check if limit reached
      if (newCopyModeIndex > copyModes.length - 1) {
        newCopyMode = copyModes[0];
      } else {
        newCopyMode = copyModes[newCopyModeIndex];
      }
    }
    //  console.log('    ' + direction + ': ' + copyModeCurrent + ' => ' + newCopyMode);

    if (copyModeCurrent !== newCopyMode) {
      // setState to the newCopyMode
      this.setState({
        copyModeCurrent: newCopyMode
      });
    }
  }

  // Constantly advances colorMode, infinite loop
  onColorModeToggle(direction) {
    // Valid: minus, plus
    //  console.log('[onColorModeToggle] Start');
    //  console.log('   this.state.colorModes = ', this.state.colorModes);

    let newColorMode,
      newColorModeIndex,
      colorModeCurrent = this.state.colorModeCurrent,
      colorModes = this.state.colorModes;

    // Find index of colorModeCurrent in colorModes
    let colorModeCurrentIndex = colorModes.findIndex(
      (colorMode) => colorMode === colorModeCurrent
    );

    if (direction === 'minus') {
      //      console.log('    direction === minus');

      // -1 to that index
      newColorModeIndex = colorModeCurrentIndex - 1;

      // Check if bottom limit reached, if yes, go to end
      if (newColorModeIndex < 0) {
        newColorMode = colorModes[colorModes.length - 1];
      } else {
        newColorMode = colorModes[newColorModeIndex];
      }
    } else {
      // +1 to that index
      newColorModeIndex = colorModeCurrentIndex + 1;

      // Check if limit reached
      if (newColorModeIndex > colorModes.length - 1) {
        newColorMode = colorModes[0];
      } else {
        newColorMode = colorModes[newColorModeIndex];
      }
    }
    //console.log('[onColorModeToggle] ' + direction + ': ' + colorModeCurrent + ' => ' + newColorMode);
    // setState to the newColorMode
    if (colorModeCurrent !== newColorMode) {
      this.setState({
        colorModeCurrent: newColorMode
      });
    }
  }

  // Constantly advances to the next section, infinite loop
  onSectionToggle() {
    let newPanelState,
      newPanelStateIndex,
      currentPanelState = this.state.currentPanelState,
      panelStates = this.state.panelStates;

    // Find index of currentPanelState in panelStates
    let currentPanelStateIndex = panelStates.findIndex(
      (panel) => panel === currentPanelState
    );

    // +1 to that index
    newPanelStateIndex = currentPanelStateIndex + 1;

    // Check if limit reached
    if (newPanelStateIndex > panelStates.length - 1) {
      newPanelState = panelStates[0];
    } else {
      newPanelState = panelStates[newPanelStateIndex];
    }

    console.log(
      '[onSectionToggle] ' + currentPanelState + ' => ' + newPanelState
    );

    // setState to the newPanelState
    this.setState({
      currentPanelState: newPanelState
    });
  }

  // Determines steps expected in onIdCopy flow
  onIdCopy(currentStep) {
    if (currentStep === undefined) {
      currentStep = 'scanFront';
    }
    let feedback;

    switch (currentStep) {
      case 'scanFront': // Immmediately scans front
        // console.log('[onIdCopy]: scanFront')
        // DONE Show number of copies without blinking.
        // DONE Ready to scan first side
        // DONE Blink idFront and id1
        // DONE Show idBack
        // DONE Scanning first side of ID, 3 seconds
        // DONE Blink power button
        // DONE Push to step 2
        feedback = this.props.config.feedbackScanning;
        this.setState(
          {
            copyCurrentHolder: this.state.copyCurrent, // Catch copy count during scan step in case of cancellation

            currentPanelState: 'printing',
            isCancelActive: true,
            isPowerBlinking: true,

            isId1Visible: true,
            isId1Blinking: true,

            isIdFrontVisible: true,
            isIdFrontBlinking: true,

            isId2Visible: false,
            isId2Blinking: false,

            isIdBackVisible: true,
            isIdBackBlinking: false,

            isLidOpenVisible: false,
            isLidOpenBlinking: false,

            isAdminIdCopyButtonVisible: false,
            isNumberBlinking: false,

            showFeedback: true,
            feedbackOverride: false,
            feedbackContent: feedback
          },
          () => {
            this.thisTimer = setTimeout(() => {
              clearTimeout(this.thisTimer);
              this.onIdCopy('prepBack');
            }, this.props.config.scanDuration);
          }
        );
        break;
      case 'prepBack': // Preparation for back scan
        // DONE Power LED goes solid
        // DONE Blink scan lid LED
        // DONE id1 hides, idFront stays
        // DONE Blink id2 and idBack
        // DONE Admin action to advance, indicating scan lid was lifted
        // console.log('[onIdCopy]: prepBack');
        //feedback = 'Waiting for admin to press "Lift scan lid"';
        feedback = '';
        this.setState({
          isPowerBlinking: false,

          isId1Visible: false,
          isId1Blinking: false,

          isIdFrontVisible: true,
          isIdFrontBlinking: false,

          isId2Visible: true,
          isId2Blinking: true,

          isIdBackVisible: true,
          isIdBackBlinking: true,

          isLidOpenVisible: true,
          isLidOpenBlinking: true,

          isAdminIdCopyButtonVisible: true,

          feedbackContent: feedback
        });
        break;
      case 'scanLidLifted': // Scan lid was lifted, admin action
        // console.log('[onIdCopy]: scanLidLifted');
        // DONE Turn off scan lid LED
        // DONE Cancel and power are only allowed here
        // DONE Show idFront and idBack and id2 (no blink)
        // DONE Blink Copy button, user presses to advance
        //feedback = 'Scan Lid Lifted, waiting on user to press Copy.';
        feedback = '';
        this.setState({
          isId2Blinking: false,
          isIdBackBlinking: false,

          isLidOpenVisible: false,
          isLidOpenBlinking: false,

          isCopyBlinking: true,
          isCopyActive: true, // Makes Copy an active button
          isAdminIdCopyButtonVisible: false, // Action button is visible

          feedbackContent: feedback
        });
        break;
      case 'scanBack': // Scan of back
        feedback = this.props.config.feedbackScanning;
        this.setState(
          {
            isCancelActive: true,
            isPowerBlinking: true,
            isCopyBlinking: false,

            isAdminIdCopyButtonVisible: false,

            feedbackContent: feedback
          },
          () => {
            this.thisTimer = setTimeout(() => {
              clearTimeout(this.thisTimer);
              this.onBasicCopy('print');
            }, this.props.config.scanDuration);
          }
        );
        break;
      default:
        console.log('[onIdCopy]:', currentStep);
    }
  }

  onPrintCountdown() {
    let thisCopyCurrent = this.state.copyCurrent - 1;
    if (thisCopyCurrent > 0) {
      this.setState(
        {
          copyCurrent: thisCopyCurrent
        },
        () => {
          this.thisTimer = setTimeout(() => {
            clearTimeout(this.thisTimer);
            this.onPrintCountdown();
          }, this.props.config.printDuration);
        }
      );
    } else {
      this.onBasicCopy('summary');
    }
  }

  onBasicCopy(currentStep) {
    if (currentStep === undefined) {
      currentStep = 'scan';
    }
    let feedback;
    switch (currentStep) {
      case 'scan': // Start, Scanning action
        feedback = this.props.config.feedbackScanning;
        this.setState(
          {
            copyCurrentHolder: this.state.copyCurrent, // Catch copy count during scan step in case of cancellation

            currentPanelState: 'printing',
            isCancelActive: true,
            isPowerBlinking: true,
            showFeedback: true,
            feedbackOverride: false,

            isId1Visible: false,
            isId1Blinking: false,
            isIdFrontVisible: false,
            isIdFrontBlinking: false,
            isId2Visible: false,
            isId2Blinking: false,
            isIdBackVisible: false,
            isIdBackBlinking: false,
            isNumberBlinking: false,

            feedbackContent: feedback
          },
          () => {
            this.thisTimer = setTimeout(() => {
              clearTimeout(this.thisTimer);
              this.onBasicCopy('print');
            }, this.props.config.scanDuration);
          }
        );
        break;
      case 'print': // Printing action
        let feedbackPrinting = this.props.config.feedbackPrintingSingle;
        if (this.state.copyCurrent > 1) {
          feedbackPrinting = this.props.config.feedbackPrintingMultiple;
        }
        feedback = feedbackPrinting.replace('REPLACE', this.state.copyCurrent);
        this.setState(
          {
            feedbackContent: feedback,
            copyCurrentHolder: this.state.copyCurrent
          },
          () => {
            this.thisTimer = setTimeout(() => {
              clearTimeout(this.thisTimer);
              this.onPrintCountdown();
            }, this.props.config.printDuration);
          }
        );
        break;
      default:
        // Conclusion summary
        if (this.state.isConclusionGeneric) {
          feedback = this.props.config.conclusionGeneric;
        } else {
          feedback = this.props.config.conclusionTitle;
        }
        this.setState({
          copyCurrent: this.state.copyCurrentHolder,
          currentPanelState: 'copy',
          feedbackContent: feedback,
          isNumberBlinking: true,
          feedbackOverride: true,
          isCancelActive: false,
          isPowerBlinking: false
        });
    }
  }

  // Called when copy button is pressed
  onCopy() {
    console.log('onCopy');
    switch (this.state.copyModeCurrent) {
      case 'id': // Step through id steps
        if (this.state.isCopyBlinking) {
          // Second instance
          this.onIdCopy('scanBack');
        } else {
          this.onIdCopy('scanFront'); // First instance
        }
        break;
      default:
        // Everyone else
        this.onBasicCopy('scan');
    }
  }

  onToggleAlert(content) {
    let showAlert = !this.state.showAlert;
    if (content) {
      this.setState({
        showAlert: showAlert,
        alertMessage: content
      });
    } else {
      this.setState({
        showAlert: showAlert,
        alertMessage: ''
      });
    }
  }

  hideFeedback() {
    console.log('hideFeedback currently', this.state.showFeedback);
    this.setState({
      showFeedback: false
    });
  }

  onShowEverything() {
    console.log('onShowEverything');
  }

  onLiftScanLid() {
    console.log('[onLiftScanLid] Life scan lid!');

    if (
      this.state.currentPanelState === 'printing' &&
      this.state.copyModeCurrent === 'id'
    ) {
      this.setState({
        isInsertOriginalVisible: false
      });
      this.onIdCopy('scanLidLifted');
    } else {
      console.log('    [onLiftScanLid] Set to copy state');
      this.setState({
        currentPanelState: 'copy',
        isPlusActive: true,
        isCopyActive: true,
        isSelectionActive: true,
        isNumberBlinking: true,
        isInsertOriginalVisible: false
      });
    }
  }

  onCancel() {
    console.log('[onCancel]');
    if (this.state.currentPanelState === 'printing') {
      //console.log(' -- [onCancel] state is printing');
      clearTimeout(this.thisTimer); // DIE TIMER!
      this.setState({
        showFeedback: false,
        isPowerBlinking: false,
        isCopyBlinking: false,
        isNumberBlinking: true,
        isLidOpenVisible: false,
        isLidOpenBlinking: false,
        currentPanelState: 'copy',
        copyCurrent: this.state.copyCurrentHolder // Resets to original count if stopped before conclusion
      });
    } else {
      //console.log(' -- [onCancel] load default starting state');
      this.loadConfig();
      this.setState({
        isCancelActive: false,
        currentPanelState: 'copy'
      });
    }
  }

  onWifi() {
    console.log('onWifi, no action');
    this.onToggleAlert(this.props.config.wifiMessage);
  }
  onInfo() {
    console.log('onInfo, no action');
    this.onToggleAlert(this.props.config.infoMessage);
  }
  onArrow() {
    console.log('onArrow, no action');
    //this.onToggleAlert( this.props.config.arrowMessage );
  }

  preloadAssets() {
    console.log('[preloadAssets] Preload all external image files');

    // Get list of all SVG assets
    let preloadAssets = [];

    // Add in numbers
    for (var i = 0; i < this.props.config.numbers.length; i++) {
      preloadAssets.push(this.props.config.numbers[i]);
    }

    // Add in contrast
    for (var k = 0; k < this.props.config.contrastNumbers.length; k++) {
      preloadAssets.push(this.props.config.contrastNumbers[k]);
    }

    // Add in active and inactive from objects
    let assetsArray = this.props.config.assets;
    for (var key in assetsArray) {
      if (key === 'length' || !assetsArray.hasOwnProperty(key)) continue; // Fancy. Unsure how it actually worked.
      if (assetsArray[key].active) {
        preloadAssets.push(assetsArray[key].active);
      }
      if (assetsArray[key].activeColor) {
        // For copy
        preloadAssets.push(assetsArray[key].activeColor);
      }
      if (assetsArray[key].inactive) {
        preloadAssets.push(assetsArray[key].inactive);
      }
    }
    preloadAssets.sort();
    //console.log('preloadAssets = ' , preloadAssets );
    let newUniquePreloadAssets = Array.from(new Set(preloadAssets)); // Fancy remove duplicates from array
    //console.log('newUniquePreloadAssets = ' , newUniquePreloadAssets );

    // Preload as img, unsure if this really preloads SVG files.
    newUniquePreloadAssets.forEach((image) => {
      //console.log('Preload', image);

      //new Image().src = image  // Fancy new way
      let img = new Image(); // Old way that does work
      img.src = image;
      this.preloadedAssets.push(img); // This forces load into a div on stage.
    });
    //    console.log(this.preloadedAssets);
  }

  render() {
    //console.log('====== PrinterPanel Render ======');
    // do not render if props.config is not ready
    //console.log(this.props.config);
    if (this.props.config === undefined) {
      return null;
    }
    return (
      <StyledPrinterDemo>
        {this.props.isDebug && (
          <CopyResultsDebug
            copies={this.state.copyCurrent}
            contrast={this.state.contrastCurrent}
            scale={this.state.scaleCurrent}
            colorMode={this.state.colorModeCurrent}
            copyMode={this.state.copyModeCurrent}
          />
        )}
        <StyledAdminControls>
          {this.props.isDebug && (
            <p>
              Debug: {this.props.config.version} /{' '}
              {this.state.currentPanelState}
            </p>
          )}
          {this.state.isOn && (
            <div className="AdminButtons">
              {!this.state.isAdminIdCopyButtonVisible &&
                this.state.isInsertOriginalVisible && (
                  <AdminButton
                    text="Insert original"
                    onClick={this.onLiftScanLid}
                    isDebug={this.props.isDebug}
                  />
                )}
              {this.state.isAdminIdCopyButtonVisible && (
                <AdminButton
                  text="Lift scan lid"
                  onClick={this.onLiftScanLid}
                  isDebug={this.props.isDebug}
                />
              )}
            </div>
          )}
        </StyledAdminControls>

        <StyledPrinterPanel
          scale={this.props.config.panelScale}
          x={this.props.config.panelX}
          y={this.props.config.panelY}
        >
          {this.state.showAlert && (
            <AlertPopUp
              content={this.state.alertMessage}
              onClick={this.onToggleAlert}
            />
          )}
          {this.state.showFeedback && (
            <FeedbackPopUp
              content={this.state.feedbackContent}
              override={this.state.feedbackOverride}
              isConclusionGeneric={this.state.isConclusionGeneric}
              onClick={this.hideFeedback}
              copies={this.state.copyCurrent}
              contrast={this.state.contrastCurrent}
              scale={this.state.scaleCurrent}
              colorMode={this.state.colorModeCurrent}
              copyMode={this.state.copyModeCurrent}
            />
          )}
          <Button
            type="power"
            config={this.props.config.assets.power}
            onClick={this.onTogglePower}
            isSystemOn={this.state.isOn}
            isPowerBlinking={this.state.isPowerBlinking}
            currentState={this.state.currentPanelState}
          />
          {this.state.isIdCopyAvailable && (
            <Button
              type="idcopy"
              config={this.props.config.assets.idcopy}
              onClick={() => {
                console.log('Press idCopy');
                if (this.state.copyModeCurrent === 'id') {
                  console.log('If id, toggle to previous one');
                  // If id, toggle to previous one
                  this.setState({
                    copyModeCurrent: this.state.copyModePrevious
                  });
                } else {
                  console.log('Else set current to id');
                  // If not id, set to current
                  this.setState({
                    copyModeCurrent: 'id'
                  });
                }
              }}
              isActive={this.state.isCopyActive}
              isSystemOn={this.state.isOn}
              colorMode={this.state.colorModeCurrent}
              currentState={this.state.currentPanelState}
            />
          )}
          <Button
            type="copy"
            config={this.props.config.assets.copy}
            onClick={this.onCopy}
            isActive={this.state.isCopyActive}
            isSystemOn={this.state.isOn}
            colorMode={this.state.colorModeCurrent}
            isCopyBlinking={this.state.isCopyBlinking}
            currentState={this.state.currentPanelState}
          />
          <Button
            type="minus"
            config={this.props.config.assets.minus}
            //onClick={this.decrement}
            onMouseDown={this.decrementDown}
            onMouseUp={this.onMouseUp}
            onMouseLeave={this.onMouseUp}
            onTouchStart={this.decrementDown}
            onTouchEnd={this.onMouseUp}
            onTouchMove={this.onMouseUp}
            isActive={this.state.isMinusActive}
            isSystemOn={this.state.isOn}
            currentState={this.state.currentPanelState}
          />
          <Button
            type="plus"
            config={this.props.config.assets.plus}
            //onClick={this.increment}
            onMouseDown={this.onMouseDown}
            onMouseUp={this.onMouseUp}
            onMouseLeave={this.onMouseUp}
            onTouchStart={this.onMouseDown}
            onTouchEnd={this.onMouseUp}
            onTouchMove={this.onMouseUp}
            isActive={this.state.isPlusActive}
            isSystemOn={this.state.isOn}
            currentState={this.state.currentPanelState}
          />
          <Button
            type="ellipsis"
            config={this.props.config.assets.ellipsis}
            onClick={this.onSectionToggle}
            isActive={this.state.isSelectionActive}
            isSystemOn={this.state.isOn}
            currentState={this.state.currentPanelState}
          />
          <Button
            type="wifi"
            config={this.props.config.assets.wifi}
            onClick={this.onWifi}
            isSystemOn={this.state.isOn}
            currentState={this.state.currentPanelState}
          />
          <Button
            type="info"
            config={this.props.config.assets.info}
            onClick={this.onInfo}
            isActive={this.state.isOnInfo}
            isSystemOn={this.state.isOn}
            currentState={this.state.currentPanelState}
          />
          <Button
            type="arrow"
            config={this.props.config.assets.arrow}
            onClick={this.onArrow}
            isSystemOn={this.state.isOn}
            isActive={this.state.isArrowActive}
            currentState={this.state.currentPanelState}
          />
          <Button
            type="cancel"
            config={this.props.config.assets.cancel}
            onClick={this.onCancel}
            isSystemOn={this.state.isOn}
            isActive={this.state.isCancelActive}
            currentState={this.state.currentPanelState}
          />

          {this.state.isOn && (
            <div className="led">
              {(this.state.currentPanelState === 'default' ||
                this.state.currentPanelState === 'copy' ||
                this.state.currentPanelState === 'printing') && (
                <NumberCount
                  type="number-count"
                  config={this.props.config.assets.numberWrapper}
                  charLimit="2"
                  currentNumber={this.state.copyCurrent}
                  isBlinking={this.state.isNumberBlinking}
                  currentState={this.state.currentPanelState}
                />
              )}
              {this.state.currentPanelState === 'scale' && (
                <NumberCount
                  type="number-count"
                  config={this.props.config.assets.numberWrapper}
                  charLimit="3"
                  currentNumber={this.state.scaleCurrent}
                  isBlinking={this.state.isNumberBlinking}
                  currentState={this.state.currentPanelState}
                />
              )}

              {this.state.isOnToner && (
                <Icon type="toner" config={this.props.config.assets.toner} />
              )}

              {this.state.currentPanelState === 'contrast' && (
                <ContrastIcon
                  config={this.props.config.assets.contrast}
                  mainConfig={this.props.config.assets.contrastMain}
                  countWrapperConfig={
                    this.props.config.assets.contrastCountWrapper
                  }
                  contrastNumbers={this.props.config.contrastNumbers}
                  contrastCurrent={this.state.contrastCurrent}
                  contrastSpread={this.props.config.contrastUpperLimit}
                />
              )}

              {this.state.currentPanelState !== 'copyMode' && (
                <>
                  {(this.state.currentPanelState === 'default' ||
                    this.state.currentPanelState === 'printing') && (
                    <Icon
                      type="copies"
                      config={this.props.config.assets.copies}
                    />
                  )}
                  {this.state.currentPanelState === 'default' && (
                    <Icon
                      type="copies"
                      config={this.props.config.assets.copies}
                    />
                  )}
                  {this.state.currentPanelState === 'copy' && (
                    <Icon
                      type="copies"
                      config={this.props.config.assets.copies}
                    />
                  )}
                  {this.state.copyModeCurrent === 'document' && (
                    <Icon
                      type="document"
                      config={this.props.config.assets.document}
                    />
                  )}
                  {this.state.copyModeCurrent === 'photo' && (
                    <Icon
                      type="photo"
                      config={this.props.config.assets.photo}
                    />
                  )}
                  {this.state.copyModeCurrent === 'id' &&
                    this.state.currentPanelState !== 'printing' && (
                      <Icon
                        type="id"
                        config={this.props.config.assets.idFront}
                      />
                    )}
                </>
              )}
              {this.state.currentPanelState === 'copyMode' && (
                <>
                  <Icon
                    type="document"
                    isPointer={true}
                    isVisible={false}
                    config={this.props.config.assets.documentPointer}
                    copyModeCurrent={this.state.copyModeCurrent}
                    copyModesAvailable={this.state.copyModes}
                  />
                  <Icon
                    type="document"
                    config={this.props.config.assets.document}
                    copyModeCurrent={this.state.copyModeCurrent}
                    copyModesAvailable={this.state.copyModes}
                  />
                  <Icon
                    type="id"
                    isPointer={true}
                    isVisible={false}
                    config={this.props.config.assets.idPointer}
                    copyModeCurrent={this.state.copyModeCurrent}
                    copyModesAvailable={this.state.copyModes}
                  />
                  <Icon
                    type="id"
                    config={this.props.config.assets.idFront}
                    copyModeCurrent={this.state.copyModeCurrent}
                    copyModesAvailable={this.state.copyModes}
                  />
                  <Icon
                    type="photo"
                    isPointer={true}
                    isVisible={false}
                    config={this.props.config.assets.photoPointer}
                    copyModeCurrent={this.state.copyModeCurrent}
                    copyModesAvailable={this.state.copyModes}
                  />
                  <Icon
                    type="photo"
                    config={this.props.config.assets.photo}
                    copyModeCurrent={this.state.copyModeCurrent}
                    copyModesAvailable={this.state.copyModes}
                  />
                </>
              )}

              {this.state.currentPanelState === 'colorMode' && (
                <>
                  {this.state.colorModeCurrent === 'cmyk' && (
                    <Icon
                      type="cmyk"
                      config={this.props.config.assets.cmyk}
                      colorModeCurrent={this.state.colorModeCurrent}
                    />
                  )}
                  {this.state.colorModeCurrent === 'k' && (
                    <Icon
                      type="k"
                      config={this.props.config.assets.k}
                      colorModeCurrent={this.state.colorModeCurrent}
                    />
                  )}
                </>
              )}

              {this.state.currentPanelState === 'printing' && (
                <>
                  <Icon
                    type="id1"
                    config={this.props.config.assets.id1}
                    isBlinking={this.state.isId1Blinking}
                    isVisible={this.state.isId1Visible}
                  />
                  <Icon
                    type="idFront"
                    config={this.props.config.assets.idFront}
                    isBlinking={this.state.isIdFrontBlinking}
                    isVisible={this.state.isIdFrontVisible}
                  />
                  <Icon
                    type="id2"
                    config={this.props.config.assets.id2}
                    isBlinking={this.state.isId2Blinking}
                    isVisible={this.state.isId2Visible}
                  />
                  <Icon
                    type="idBack"
                    config={this.props.config.assets.idBack}
                    isBlinking={this.state.isIdBackBlinking}
                    isVisible={this.state.isIdBackVisible}
                  />
                </>
              )}

              {this.state.isOnBluetooth && (
                <Icon
                  type="bluetooth"
                  config={this.props.config.assets.bluetooth}
                />
              )}
              {this.state.isOnWifi && (
                <Icon
                  type="wifiIcon"
                  config={this.props.config.assets.wifiIcon}
                />
              )}
              {this.state.isOnEthernet && (
                <Icon
                  type="network"
                  config={this.props.config.assets.network}
                />
              )}
              {this.state.currentPanelState === 'scale' && (
                <Icon type="scale" config={this.props.config.assets.scale} />
              )}

              <Icon
                type="lidOpen"
                config={this.props.config.assets.lidOpen}
                isBlinking={this.state.isLidOpenBlinking}
                isVisible={this.state.isLidOpenVisible}
              />

              {this.state.mediaAlert && (
                <Icon
                  type="mediaAlert"
                  config={this.props.config.assets.mediaAlert}
                />
              )}
            </div>
          )}
        </StyledPrinterPanel>
        <StyledPreload>
          {this.preloadedAssets.map((img, index) => (
            <img src={img.src} key={'img' + index} alt="" />
          ))}
        </StyledPreload>
      </StyledPrinterDemo>
    );
  }
}

export default PrinterPanel;
