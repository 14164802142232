import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledAlertPopUp = styled.div`
  display: block;
  position: relative;
  z-index: 200;
  top: 0;
  left: 0;
  background-color: ${props => props.theme.feedbackOverlayBgColor};
  z-index: 10;
  color: ${props => props.theme.lightColor};
  width: 100vm;
  height: 100vh;
  .content {
    position: relative;
    z-index: 100;
    background-color: ${props => props.theme.feedbackBgColor};
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.6);
    text-align: center;
    width: 200px;
    height: 90px;
    top: 10px;
    padding: 0 25px;
    margin: 0 auto;
    border-radius: 8px;
    h2 {
      font-size: ${props => props.theme.fontSize};
      margin: 0;
      padding: 15px 0;
    }
    h3 {
      font-size: ${props => props.theme.fontSizeSmall};
      text-transform: uppercase;
      font-weight: bold;
      margin: 0;
      padding: 5px 0;
    }
  }
`;

class AlertPopUp extends Component {
  render() {
    const { onClick, content } = this.props;
    return (
      <StyledAlertPopUp onClick={onClick} content={content}>
        <div className="content">
          {content && <h2>{content}</h2>}
          <h3>Close</h3>
        </div>
      </StyledAlertPopUp>
    );
  }
}

AlertPopUp.defaultProps = {
  content: 'hello'
};

AlertPopUp.propTypes = {
  onClick: PropTypes.func,
  content: PropTypes.string
};

export default AlertPopUp;
