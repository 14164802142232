import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CopyResults from '../CopyResults';

const StyledCopyResultsDebug = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  color: ${props => props.theme.debugColor};
  width: 100vw;
  text-align: center;

  dl {
    clear: both;
    padding: 4px 10px;
    margin: 2px;
    border: 1px solid ${props => props.theme.debugColor};
    display: inline-block;
  }
  dt {
    font-size: ${props => props.theme.fontSizeSmall};
    display: inline-block;
    margin-right: 10px;
  }
  dd {
    font-size: ${props => props.theme.fontSizeSmall};
    padding: 0;
    margin: 0;
    display: inline-block;
  }
`;

class CopyResultsDebug extends Component {
  render() {
    const { copies, contrast, scale, colorMode, copyMode } = this.props;

    return (
      <StyledCopyResultsDebug>
        <CopyResults
          copies={copies}
          contrast={contrast}
          scale={scale}
          colorMode={colorMode}
          copyMode={copyMode}
        />
      </StyledCopyResultsDebug>
    );
  }
}

CopyResultsDebug.defaultProps = {
  override: false
};

CopyResultsDebug.propTypes = {
  copies: PropTypes.number,
  contrast: PropTypes.number,
  content: PropTypes.string,
  colorMode: PropTypes.string,
  copyMode: PropTypes.string,
  scale: PropTypes.number
};

export default CopyResultsDebug;
