import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledCopyResults = styled.div`
  display: block;
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  text-align: center;

  dl {
    clear: both;
    padding: 4px 10px;
    margin: 2px;
    border: 1px solid ${props => props.theme.debugColor};
    display: inline-block;
  }
  dt {
    font-size: ${props => props.theme.fontSizeSmall};
    display: inline-block;
    margin-right: 10px;
  }
  dd {
    font-size: ${props => props.theme.fontSizeSmall};
    padding: 0;
    margin: 0;
    display: inline-block;
  }
`;

class CopyResults extends Component {
  render() {
    const { copies, contrast, scale, colorMode, copyMode } = this.props;

    const CONFIG = window.CONFIG; // It works!

    let contrastFeedback = '',
      colorCopyFeedback = '';

    if (contrast) {
      let contrastFeedbackOptions = CONFIG.contrastFeedback;
      contrastFeedback = contrastFeedbackOptions[contrast - 1];
    }
    if (colorMode && copyMode) {
      let copyModesFeedbackOptions = CONFIG.copyModesFeedback,
        copyModes = CONFIG.copyModes,
        colorModesFeedbackOptions = CONFIG.colorModesFeedback,
        colorModes = CONFIG.colorModes;

      colorCopyFeedback =
        colorModesFeedbackOptions[colorModes.indexOf(colorMode)] + ' ';
      colorCopyFeedback +=
        copyModesFeedbackOptions[copyModes.indexOf(copyMode)];
    }

    return (
      <StyledCopyResults>
        <dl>
          <dt>Copies</dt>
          <dd>{copies}</dd>
        </dl>
        <dl>
          <dt>Contrast</dt>
          <dd>{contrastFeedback}</dd>
        </dl>
        <dl>
          <dt>Scale</dt>
          <dd>
            {scale}
            {CONFIG.scaleFeedback}
          </dd>
        </dl>
        <dl>
          <dt>Color/Copy Mode</dt>
          <dd>{colorCopyFeedback}</dd>
        </dl>
      </StyledCopyResults>
    );
  }
}

CopyResults.defaultProps = {
  override: false
};

CopyResults.propTypes = {
  copies: PropTypes.number,
  contrast: PropTypes.number,
  content: PropTypes.string,
  colorMode: PropTypes.string,
  copyMode: PropTypes.string,
  scale: PropTypes.number
};

export default CopyResults;
