import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icon from '../Icon';
//import { CONFIG } from '../../constants/';

const StyledNumberCount = styled.div`
  display: block;
  position: absolute;
  z-index: 5;
  color: red;
  width: ${props => props.width || '40px'};
  height: ${props => props.height || '40px'};
  left: ${props => props.x || 0};
  top: ${props => props.y || 0};
  cursor: no-cursor;
  text-overflow: hidden;
`;
const StyledSingleDigit = styled.div`
  display: inline-block;
  width: ${props => props.width || '15px'};
  height: ${props => props.height || '28px'};
  background-image: url(${props => props.imageUri || ''});
  background-color: ${props => props.theme.debugColorPlacement};
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  margin-right: 2px;
`;

class NumberCount extends Component {
  render() {
    const {
      config,
      currentNumber,
      charLimit,
      currentState,
      isBlinking
    } = this.props;

    const CONFIG = window.CONFIG; // It works!

    // Transform number to 000 format
    let currentNumberString,
      bufferDigits = '',
      className = '',
      isScale = false;

    if (currentNumber < 10) {
      bufferDigits = '00';
    } else if (currentNumber < 100) {
      bufferDigits = '0';
    }

    currentNumberString = bufferDigits + currentNumber.toString();

    let currentNumberArray = currentNumberString.split('');

    let imageUri1 = CONFIG.numbers[currentNumberArray[0]],
      imageUri2 = CONFIG.numbers[currentNumberArray[1]],
      imageUri3 = CONFIG.numbers[currentNumberArray[2]];

    if (Number(charLimit) === 2) {
      imageUri1 = '';
    }

    if (currentState === 'scale') {
      isScale = true;
    }

    if (isBlinking) {
      className = 'blink';
    }

    return (
      <div className={className}>
        <StyledNumberCount
          x={config.x}
          y={config.y}
          width={config.width}
          height={config.height}
        >
          <StyledSingleDigit
            width={config.digitWidth}
            height={config.digitHeight}
            imageUri={imageUri1}
          ></StyledSingleDigit>
          <StyledSingleDigit
            width={config.digitWidth}
            height={config.digitHeight}
            imageUri={imageUri2}
          ></StyledSingleDigit>
          <StyledSingleDigit
            width={config.digitWidth}
            height={config.digitHeight}
            imageUri={imageUri3}
          ></StyledSingleDigit>
        </StyledNumberCount>
        {isScale && <Icon type="percent" config={CONFIG.assets.percent} />}
      </div>
    );
  }
}

NumberCount.defaultProps = {
  className: ''
};

NumberCount.propTypes = {
  config: PropTypes.object,
  currentState: PropTypes.string,
  currentNumber: PropTypes.number,
  charlimit: PropTypes.number,
  isBlinking: PropTypes.bool
};

export default NumberCount;
