import React, { Component } from 'react';
import styled from 'styled-components';
import CopyResults from '../CopyResults';
//import { CONFIG } from '../../constants/';
import PropTypes from 'prop-types';

const StyledFeedbackPopUp = styled.div`
  display: block;
  position: absolute;
  top: ${(props) => props.parentTop || '0'};
  left: 0;
  background-color: ${(props) => props.theme.feedbackOverlayBgColor};
  z-index: 10;
  color: ${(props) => props.theme.lightColor};
  width: 100%;
  height: 100%;

  .content {
    position: relative;
    text-align: center;
    width: 550px;
    height: auto;
    top: ${(props) => props.summaryTop || '0'};
    padding: 0;
    margin: 0 auto;
    h1 {
      font-size: ${(props) => props.theme.fontSizeLarge};
      margin: 0;
      padding: 0;
      top: 0;
      color: ${(props) => props.theme.lightColor};
    }
    p {
      font-size: ${(props) => props.theme.fontSizeSmall};
      margin: 0;
      padding: 15px 0;
    }
    dl {
      clear: both;
      padding: 4px 10px;
      margin: 2px;
      border: 1px solid ${(props) => props.theme.lightColor};
      display: inline-block;
    }
    dt {
      font-size: ${(props) => props.theme.fontSizeSmall};
      display: inline-block;
      margin-right: 10px;
    }
    dd {
      font-size: ${(props) => props.theme.fontSizeSmall};
      padding: 0;
      margin: 0;
      display: inline-block;
    }
  }
`;

class FeedbackPopUp extends Component {
  constructor(props) {
    super(props);
    this.stopPropagation = this.stopPropagation.bind(this);
  }

  stopPropagation(e) {
    e.stopPropagation();
  }

  render() {
    const {
      onClick,
      content,
      override,
      isConclusionGeneric,
      copies,
      contrast,
      scale,
      colorMode,
      copyMode
    } = this.props;

    const CONFIG = window.CONFIG; // It works!

    let summaryTop = 0,
      parentTop = 0,
      blink = '',
      thisClick = onClick;

    if (override) {
      summaryTop = CONFIG.feedbackSpacing;
    } else {
      parentTop = CONFIG.feedbackSpacing;
      thisClick = undefined;
    }

    return (
      <StyledFeedbackPopUp
        onClick={thisClick}
        parentTop={parentTop}
        summaryTop={summaryTop}
      >
        <div className="content">
          {content && <h1 className={blink}>{content}</h1>}
          {override && (
            <>
              {!isConclusionGeneric && (
                <>
                  <>{CONFIG.conclusionDescription}</>
                  <CopyResults
                    copies={copies}
                    contrast={contrast}
                    scale={scale}
                    colorMode={colorMode}
                    copyMode={copyMode}
                  />
                </>
              )}
            </>
          )}
        </div>
      </StyledFeedbackPopUp>
    );
  }
}

FeedbackPopUp.defaultProps = {
  override: false
};

FeedbackPopUp.propTypes = {
  onClick: PropTypes.func,
  override: PropTypes.bool,
  isConclusionGeneric: PropTypes.bool,
  copies: PropTypes.number,
  contrast: PropTypes.number,
  content: PropTypes.string,
  colorMode: PropTypes.string,
  copyMode: PropTypes.string,
  scale: PropTypes.number
};

export default FeedbackPopUp;
