import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledAdminButton = styled.button`
  display: inline-block;
  position: relative;
  z-index: 4;
  color: ${props => props.color};
  width: auto;
  height: auto;
  overflow: hidden;
  text-align: center;
  background-color: ${props => props.theme.demoBgColor};
  border: 0;
  left: ${props => props.x || 0};
  top: ${props => props.y || 0};
  padding: 10px;
  margin: 10px;
  border-radius: 8px;
  font-size: 12px;
  text-transform: uppercase;
`;

const AdminButton = ({ onClick, x, y, text, isDebug }) => {
  const CONFIG = window.CONFIG; // It works!

  let color = CONFIG.adminDebugTextColor;
  if (!isDebug) {
    // Not in debug
    color = CONFIG.adminTextColor;
  }

  return (
    <StyledAdminButton onClick={onClick} color={color} x={x} y={y}>
      {text}
    </StyledAdminButton>
  );
};

AdminButton.defaultProps = {
  isDebug: true
};

AdminButton.propTypes = {
  onClick: PropTypes.func,
  x: PropTypes.string,
  y: PropTypes.string,
  text: PropTypes.string,
  isDebug: PropTypes.bool
};

export default AdminButton;
