import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledContrastIcon = styled.div`
  display: block;
  position: absolute;
  z-index: 5;
  color: red;
  width: ${(props) => props.width || '40px'};
  height: ${(props) => props.height || '40px'};
  left: ${(props) => props.x || 0};
  top: ${(props) => props.y || 0};
  background-image: url(${(props) => props.imageUri || ''});
  background-color: ${(props) => props.theme.debugColorPlacement};
  background-position: center center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: no-cursor;
  text-overflow: hidden;
`;

const StyledMainIcon = styled.div`
  display: block;
  position: absolute;
  z-index: 5;
  color: red;
  width: ${(props) => props.width || '18px'};
  height: ${(props) => props.height || '18px'};
  left: ${(props) => props.x || 0};
  top: ${(props) => props.y || 0};
  background-image: url(${(props) => props.imageUri || ''});
  background-color: ${(props) => props.theme.debugColorPlacement};
  background-position: center center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: no-cursor;
  text-overflow: hidden;
`;

const StyledCountWrapper = styled.div`
  display: block;
  position: absolute;
  z-index: 5;
  color: red;
  width: ${(props) => props.width || '22px'};
  height: ${(props) => props.height || '8px'};
  left: ${(props) => props.x || 0};
  top: ${(props) => props.y || 0};
  cursor: no-cursor;
  background-color: ${(props) => props.theme.debugColorPlacement};
  text-overflow: hidden;
  text-align: center;
`;

const StyleCountIcon = styled.div`
  display: block;
  float: left;
  position: relative;
  width: ${(props) => props.width || '2px'};
  height: ${(props) => props.height || '6px'};
  background-image: url(${(props) => props.imageUri || ''});
  background-color: ${(props) => props.theme.debugColorPlacement};
  background-position: center center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  left: ${(props) => props.x || 0};
  top: ${(props) => props.y || 0};
  padding: 0;
`;

class ContrastIcon extends Component {
  constructor(props) {
    super(props);
    this.stopPropagation = this.stopPropagation.bind(this);
  }
  stopPropagation(e) {
    e.stopPropagation();
  }

  render() {
    const {
      config,
      mainConfig,
      countWrapperConfig,
      contrastNumbers,
      contrastCurrent
    } = this.props;

    let currentContrastIconImage = contrastNumbers[contrastCurrent - 1],
      className = 'blink';

    return (
      <StyledContrastIcon
        x={config.x}
        y={config.y}
        width={config.width}
        height={config.height}
      >
        <StyledMainIcon
          x={mainConfig.x}
          y={mainConfig.y}
          width={mainConfig.width}
          height={mainConfig.height}
          imageUri={mainConfig.active}
        />
        <StyledCountWrapper
          x={countWrapperConfig.x}
          y={countWrapperConfig.y}
          width={countWrapperConfig.width}
          height={countWrapperConfig.height}
        >
          <StyleCountIcon
            className={className}
            width={countWrapperConfig.width}
            height={countWrapperConfig.height}
            imageUri={currentContrastIconImage}
          />
        </StyledCountWrapper>
      </StyledContrastIcon>
    );
  }
}

ContrastIcon.propTypes = {
  config: PropTypes.object,
  mainConfig: PropTypes.object,
  countConfig: PropTypes.object,
  contrastCurrent: PropTypes.number,
  contrastSpread: PropTypes.number,
  contrastNumbers: PropTypes.array,
  countWrapperConfig: PropTypes.object
};

export default ContrastIcon;
